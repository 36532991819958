import {
  takeEvery,
  CallEffect,
  PutEffect,
  call,
  put,
} from 'redux-saga/effects';

import oWnApi from 'utils/WnApi';
import metaActions from './actions';

const fetchFeatureFlags = async () => {
  const response = await oWnApi.get('feature_flags', false);
  return response;
};

function* getFeatureFlags(): Generator<CallEffect | PutEffect, void, any> {
  try {
    const response = yield call(fetchFeatureFlags);
    if (response?.status === 200) {
      yield put(metaActions.setFeatureFlags(response.data));
    } else {
      throw new Error(
        `${response.status}:${response.statusText}; ${response.data}`
      );
    }
  } catch (error) {
    console.error('Failed to fetch app feature flags', error);
  }
}

export default function* watchMeta() {
  yield takeEvery('Meta/GET_FEATURE_FLAGS', getFeatureFlags);
}
