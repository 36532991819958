import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import Button from 'components/Buttons/Button';
import wnApi from 'utils/WnApi';
import { currentUserLookupIdSelector } from 'redux/selectors';

import TaxStatementForm from './TaxStatementForm';
import { UiPhase, TaxYear } from './types';
import usePollForPdf from './usePollForPdf';
import TaxStatementInfo from './TaxStatementInfo';

type TaxStatementsProps = {
  taxYears?: TaxYear[];
};

// Allows the user to request and download their tax statements by year
const TaxStatements = ({ taxYears = [] }: TaxStatementsProps) => {
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);
  const [taxStatementPdfUrl, setTaxStatementPdfUrl] = useState<string>('');
  const [taxYear, setTaxYear] = useState<string>('');
  const [uiPhase, setUiPhase] = useState<UiPhase>('form');
  const { pollForPdfUrl, stopPolling } = usePollForPdf();
  const abortControllerRef = useRef<AbortController | null>(null);

  // Cancel any pending requests when the component unmounts
  useEffect(
    () => () => {
      abortControllerRef.current?.abort();
    },
    []
  );

  const reset = () => {
    stopPolling();
    setUiPhase('form');
    setTaxStatementPdfUrl('');
    setTaxYear('');
    abortControllerRef.current?.abort();
    abortControllerRef.current = null;
  };

  const handleGetPdf = async (formValues: { year: string }) => {
    const { year } = formValues;
    setTaxYear(year);
    const path: string = `constituents/${sCurrentUserLookupId}/reports/tax_statement?targetLookupId=${sCurrentUserLookupId}&year=${year}`;
    try {
      abortControllerRef.current = new AbortController();
      const response: AxiosResponse = await wnApi.get<AxiosResponse>(
        path,
        true,
        abortControllerRef.current.signal
      );
      abortControllerRef.current = null;

      if (response.status !== 200) {
        throw new Error(
          `Something went wrong while generating the tax statement. ${response.status}:${response.statusText}; ${response.data}`
        );
      }

      let pdfUrl: string = '';
      if (response.data.pdfUrl) {
        pdfUrl = response.data.pdfUrl;
      } else {
        pdfUrl = await pollForPdfUrl(path);
      }

      setTaxStatementPdfUrl(pdfUrl);
      setUiPhase('success');
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted.');
      } else {
        console.error(error);
        setUiPhase('error');
      }
    }
  };

  return (
    <>
      <div className='myGiving__dividerTitleBlock'>
        <span className='myGiving__dividerTitle'>
          Athletic Tax Statements by Calendar Year
        </span>
        <TaxStatementInfo />
      </div>
      <div className='taxStatements__content'>
        {uiPhase === 'form' && (
          <TaxStatementForm taxYears={taxYears} handleSubmit={handleGetPdf} />
        )}
        {uiPhase === 'success' && (
          <div className='taxStatements__successContent'>
            <p className='taxStatements__expirationMsg t-paragraph'>
              The generated link is valid for the next 15 minutes. Please click
              the links below to view, print, or download your {taxYear} tax
              statement and Benefit Chart.
            </p>
            <a
              href={taxStatementPdfUrl}
              target='_blank'
              rel='noreferrer'
              download
              className='taxStatements__pdfLink'
            >
              View, print, or download {taxYear} tax statement
            </a>
            <a
              href={`https://go.wfu.edu/DC-Tax-Chart-${taxYear}/`}
              target='_blank'
              rel='noreferrer'
              className='taxStatements__pdfLink'
            >
              View, print, or download {taxYear} tax Benefit Chart
            </a>
          </div>
        )}
        {uiPhase === 'error' && <p>Something went wrong. Please try again.</p>}
        {['success', 'error'].includes(uiPhase) && (
          <Button fnHandleClick={reset} sFlavor='secondary'>
            Back
          </Button>
        )}
      </div>
    </>
  );
};

export default TaxStatements;
