import React, { useState } from 'react';

import Modal from 'components/Layout/Modal/Modal';
import Button from 'components/Buttons/Button';
import usePageTitle from 'utils/hooks/usePageTitle';
import useCurrentEventCartItem from '../useCurrentEventCartItem';
import CreateAGuest from './CreateAGuest';
import GuestYesNo from './GuestYesNo';
import ConstituentSearch from '../ConstituentSearch';
import { fnAddGuestToEventInCart } from '../redux/guestHelpers';
import { fnCalculateMaxNumberOfRegistrantsForEvent } from '../helpers';

const GuestManagement = () => {
  const [sGuestUIPhase, fnSetGuestUIPhase] = useState('');
  const [oNewGuestInitialValues, fnSetNewGuestInitialValues] = useState(null);
  const [bShowDuplicateGuestModal, fnSetShowDuplicateGuestModal] =
    useState(false);
  const [sDuplicateGuestErrorMsg, fnSetDuplicateGuestErrorMsg] = useState('');
  const oEventCartItem = useCurrentEventCartItem();
  const bIsWaitlistEvent = oEventCartItem?.sType === 'event-waitlist';

  usePageTitle('Event Registration - Guests');

  if (!oEventCartItem) {
    return null;
  }
  const {
    TOTAL_CAPACITY = 0,
    TOTAL_REGISTRATIONS = 0,
    MAX_GUESTS = 0,
  } = oEventCartItem.oDetails.oEvent.REGISTRATION ?? {};
  const iMaxRegistrants = fnCalculateMaxNumberOfRegistrantsForEvent(
    bIsWaitlistEvent ? 0 : TOTAL_CAPACITY,
    TOTAL_REGISTRATIONS,
    MAX_GUESTS
  );
  const iNumGuests = oEventCartItem.oDetails.aGuests.length;
  if (
    (oEventCartItem.oDetails.oEvent &&
      iNumGuests >= iMaxRegistrants &&
      !bIsWaitlistEvent) ||
    (iNumGuests >= MAX_GUESTS + 1 && bIsWaitlistEvent)
  ) {
    return <p>You have reached the max number of guests for this event.</p>;
  }

  const fnHandleNewGuest = (sGuestName, sGuestLookupId, oNewGuestData) => {
    try {
      fnAddGuestToEventInCart(
        sGuestName,
        sGuestLookupId,
        oEventCartItem.oDetails.oEvent.LOOKUPID,
        oNewGuestData
      );
    } catch (oError) {
      console.error(oError);
      if (oError.message === 'DUPLICATE_GUEST') {
        const sEventTitle = oEventCartItem.oDetails.oEvent.TITLE;
        fnSetDuplicateGuestErrorMsg(
          `You already added ${sGuestName} as a guest for ${sEventTitle}.`
        );
        fnSetShowDuplicateGuestModal(true);
      }
    }
  };

  switch (sGuestUIPhase) {
    case 'search':
      return (
        <div>
          <h1 className='guestManagment__searchInstructions t-paragraph--medium'>
            Tell us who you would like to bring.
          </h1>
          <ConstituentSearch
            fnSetGuestUIPhase={fnSetGuestUIPhase}
            fnSetNewGuestInitialValues={fnSetNewGuestInitialValues}
            fnHandleNewConstituent={fnHandleNewGuest}
          />
          {bShowDuplicateGuestModal && (
            <Modal
              fnSetShowModal={fnSetShowDuplicateGuestModal}
              sModalSubtitle={sDuplicateGuestErrorMsg}
              sModalDetail='Please select a different guest or close this modal to continue.'
            >
              <Button fnHandleClick={() => fnSetShowDuplicateGuestModal(false)}>
                Close
              </Button>
            </Modal>
          )}
        </div>
      );
    case 'create':
      return (
        <CreateAGuest
          oNewGuestInitialValues={oNewGuestInitialValues}
          fnSetGuestUIPhase={fnSetGuestUIPhase}
          fnHandleNewGuest={fnHandleNewGuest}
        />
      );
    default:
      return <GuestYesNo fnSetGuestUIPhase={fnSetGuestUIPhase} />;
  }
};

export default GuestManagement;
