import { createActions } from 'redux-actions';

const metaActions = createActions(
  {
    GET_FEATURE_FLAGS: (action) => action,
    SET_FEATURE_FLAGS: (action) => action,
  },
  {
    prefix: 'Meta',
  }
);

export default metaActions;
