import * as Yup from 'yup';

import { TaxYear } from './types';

export const buildTaxYearOptions = (taxYears: TaxYear[] = []) => {
  const options = taxYears.map((taxYear: TaxYear) => ({
    label: taxYear.YEAR,
    value: taxYear.YEAR,
  }));
  return options;
};

export const buildTaxStatementFormInitialValues = (
  taxYears: TaxYear[] = []
) => {
  const intialValues = {
    year: taxYears.length > 0 ? taxYears[0].YEAR : '',
  };
  return intialValues;
};

export const buildTaxStatementFormSchema = (taxYears: TaxYear[] = []) => {
  const schema = {
    year: Yup.string()
      .oneOf(
        taxYears.map((taxYear) => taxYear.YEAR),
        'Invalid year'
      )
      .required('Please choose a year from the list.'),
  };
  return Yup.object().shape(schema);
};
