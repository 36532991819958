import { handleActions } from 'redux-actions';
import metaActions from './actions';

const initialState = {
  featureFlags: null,
};

const options = { prefix: 'Meta' };

const reducer = handleActions(
  {
    [metaActions.setFeatureFlags]: {
      next(state, { payload }) {
        return { ...state, featureFlags: payload };
      },
      throw(state) {
        return state;
      },
    },
  },
  initialState,
  options
);

export default reducer;
