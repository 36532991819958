import React, { useState } from 'react';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import Modal from 'components/Layout/Modal/Modal';
import Button from 'components/Buttons/Button';

const TaxStatementInfo = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <IconOnlyButton
        sIcon='info'
        fnHandleClick={() => {
          setShowModal(true);
        }}
        sAriaLabel='Tax statement help'
        sFlavor='tertiary'
        className='taxStatementInfo__button'
      />
      {showModal && (
        <Modal
          sModalTitle='Foobar'
          fnSetShowModal={setShowModal}
          sFlavor='message'
        >
          <p className='taxStatementInfo__modalMessage'>
            The available tax years correspond to the years you contributed to
            athletics. Choose a year to generate a secure PDF link to your tax
            statement and access that year&apos;s tax benefit chart.
          </p>
          <Button
            sFlavor='secondary'
            fnHandleClick={() => {
              setShowModal(false);
            }}
            className='taxStatementInfo__modalButton'
          >
            Back
          </Button>
        </Modal>
      )}
    </>
  );
};

export default TaxStatementInfo;
